/* Genel Ayarlar */
body {
    background-color: #05A561;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
}

.header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.logo-image {
    max-width: 100%;
    height: auto;
    width: 300px;
    transition: width 0.3s ease;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

/* Ortak Buton Stili */
.wallet-button, .purchase-button {
    background-color: #083a0b;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    z-index: 2;
    outline: none;
    font-family: 'Poppins-Bold'; /* Fontu Poppins-Bold olarak ayarladık */
    margin: 10px 0;
}

.wallet-button:hover, .purchase-button:hover {
    background-color: #066b0b;
}

.buy-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    z-index: 4;
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.buy-section.visible {
    opacity: 1;
}

.countdown {
    font-size: 1.5rem;
    color: #ffffff;
    margin: 30px auto 0 auto;
    align-items: center;
    background-color: #083a0b;
    padding: 8px 16px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
}

.sol-input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    width: 200px;
}

.worldcloudy-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    margin-bottom: -30%;
    z-index: 2;
}

.rocket-image {
    position: absolute;
    left: 10%;
    top: 30%;
    max-width: 25%;
    height: auto;
    z-index: 1;
}

.gumbyspace-image {
    position: absolute;
    right: 10%;
    top: 32%;
    max-width: 30%;
    height: auto;
    z-index: 3;
}

.footer {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -120px;
}

.cloudy-image {
    width: 100vw;
    height: auto;
    min-width: 100%;
    max-width: 100%;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 20px;
    border: 2px solid #05A561;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 400px;
    max-height: 200px;
    animation: fadeIn 0.3s ease-in-out;
    overflow: hidden;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #333;
}

.modal-content span {
    align-self: flex-end;
    cursor: pointer;
    font-size: 18px;
    color: #05A561;
}

.modal-content p {
    color: #333;
    font-size: 16px;
    text-align: center;
    margin-top: 10px;
}

.modal-content button {
    background-color: #05A561;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin-top: 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    font-family: 'Poppins-Bold'; /* Fontu Poppins-Bold olarak ayarladık */
}

.modal-content button:hover {
    background-color: #066b0b;
}

.wallet-info {
    margin-top: 20px;
    text-align: center;
    color: #fff;
}

.wallet-info p {
    margin: 5px 0;
    font-size: 1rem;
}

.wallet-info strong {
    color: #ffd700;
}

/* Progress Bar Stil Ayarları */
.progress-container {
    width: 80%;
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
}

.progress-bar {
    background-color: #083a0b;
    border-radius: 5px;
    overflow: hidden;
    height: 20px;
    width: 100%;
    margin: 10px 0;
}

.progress-bar-fill {
    height: 100%;
    background-color: #ffd700;
    width: 0%;
    transition: width 0.5s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 768px) {
    .logo-image {
        width: 200px;
    }
    .wallet-button, .purchase-button {
        font-size: 0.9rem;
        padding: 8px 16px;
    }
    .rocket-image {
        left: 5%;
        top: 20%;
        max-width: 30%;
    }
    .gumbyspace-image {
        right: 5%;
        top: 25%;
        max-width: 35%;
    }
    .sol-input {
        width: 150px;
        font-size: 0.9rem;
    }

    .worldcloudy-container {
        margin-bottom: 0;
        margin-top: 2000%;
    }
    .buy-section {
        margin-top: 40px;
    }

    .countdown {
        font-size: 1.3rem;
        padding: 6px 12px;
    }

    .modal {
        width: 90%;
        max-width: 350px;
        padding: 15px;
    }
  
    .modal-content p {
        font-size: 14px;
    }

    .progress-container {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .logo-image {
        width: 150px;
    }
    .wallet-button, .purchase-button {
        font-size: 0.8rem;
        padding: 6px 12px;
    }
    .rocket-image {
        left: 50%;
        top: 25%;
        max-width: 35%;
    }
    .gumbyspace-image {
        right: 50%;
        top: 30%;
        max-width: 40%;
    }
    .sol-input {
        width: 120px;
        font-size: 0.8rem;
    }

    .worldcloudy-container {
        margin-bottom: 0;
        margin-top: 20%;
    }
    .buy-section {
        margin-top: 12%;
    }

    .countdown {
        font-size: 1.1rem;
        padding: 5px 10px;
    }

    .modal {
        width: 95%;
        max-width: 300px;
        padding: 10px;
    }
  
    .modal-content span {
        font-size: 16px;
    }
  
    .modal-content p {
        font-size: 12px;
    }

    .progress-container {
        width: 95%;
    }
}
