@font-face {
    font-family: 'PolenoSemiBold';
    src: url('fonts/Poleno SemiBold DEMO/fonnts.com-Poleno_SemiBold.otf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Black';
    src: url('fonts/poppins/Poppins-Black.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url('fonts/poppins/Poppins-BlackItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('fonts/poppins/Poppins-Bold.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('fonts/poppins/Poppins-BoldItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('fonts/poppins/Poppins-ExtraBold.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-ExtraBoldItalic';
    src: url('fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-ExtraLight';
    src: url('fonts/poppins/Poppins-ExtraLight.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-ExtraLightItalic';
    src: url('fonts/poppins/Poppins-ExtraLightItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('fonts/poppins/Poppins-Italic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('fonts/poppins/Poppins-Light.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-LightItalic';
    src: url('fonts/poppins/Poppins-LightItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('fonts/poppins/Poppins-Medium.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-MediumItalic';
    src: url('fonts/poppins/Poppins-MediumItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('fonts/poppins/Poppins-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('fonts/poppins/Poppins-SemiBold.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('fonts/poppins/Poppins-SemiBoldItalic.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-Thin';
    src: url('fonts/poppins/Poppins-Thin.ttf') format('opentype');
}

@font-face {
    font-family: 'Poppins-ThinItalic';
    src: url('fonts/poppins/Poppins-ThinItalic.ttf') format('opentype');
}

/* Genel CSS Ayarları (Masaüstü için) */
body {
    background-color: #05A561;
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow-x: hidden; /* Yatay kaydırmayı önler */
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 0 20px;
    position: relative;
}

.left-buttons {
    display: flex;
    gap: 15px;
    position: absolute;
    left: 20px;
}

.right-button {
    position: absolute;
    right: 20px;
}

.logo-image {
    max-width: 300px;
    height: auto;
}

button {
    font-size: 18px;
    font-family: 'Poppins';
    padding: 12px 25px;
    border-radius: 15px 50px 30px 60px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

button:hover {
    transform: scale(1.1);
}

.buy-button:hover {
    transform: scale(1.2);
}

.transparent-button {
    background-color: transparent;
    color: white;
    border: 2px solid #01541e;
}

.transparent-button:hover {
    border-color: #5eff96;
}

.buy-button {
    background-color: #00c144;
    color: white;
    border: none;
    font-size: 18px;
    padding: 15px 30px;
}

.buy-button:hover {
    background-color: #006423;
}

.gumby-text {
    font-family: 'PolenoSemiBold', sans-serif;
    color: white;
    font-size: 120px;
    font-weight: bold;
    letter-spacing: 4px;
    text-align: center;
    line-height: 1.2;
    padding-top: 10px;
}

.description-text {
    font-family: 'Poppins-Light';
    color: #acf054;
    font-size: 18px;
    text-align: center;
    max-width: 40%;
    margin: 1px auto 18px auto;
    line-height: 1.2;
}

.additional-buttons {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-top: 60px;
}

.world-cloudy-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 150px;
    position: relative;
}

img.background-image {
    width: 100%;
    height: auto;
    z-index: 2;
}

img.rocket-image {
    position: absolute;
    left: 100px;
    top: -75px;
    max-width: 430px;
    height: auto;
    z-index: 1;
}

img.gumbyspace-image {
    position: absolute;
    right: 20px;
    top: -130px;
    max-width: 530px;
    height: auto;
    z-index: 3;
}

img.gumbyhero-image {
    position: absolute;
    left: 500px;
    transform: translateX(-50%);
    top: 650px;
    max-width: 400px;
    height: auto;
    z-index: 4;
}

img.cloudywhite-image {
    position: absolute;
    left: 0;
    width: 100%;
    top: 320px;
    height: auto;
    z-index: 3;
}

.about-gumby {
    position: absolute;
    right: 100px;
    top: 770px;
    color: white;
    text-align: left;
    max-width: 700px;
    z-index: 3;
}

.about-gumby h2 {
    font-family: 'PolenoSemiBold', sans-serif;
    color: #36a964;
    font-size: 50px;
    margin-bottom: -1px;
    z-index: 3;
}

.about-gumby p {
    font-family: 'Poppins';
    color: #a0d223;
    font-size: 24px;
    line-height: 1;
    z-index: 3;
}

.lower-section {
    display: flex;
    justify-content: flex-start;
    padding: 25px;
    background-color: #157c48;
    color: white;
    width: 40%;
    margin-left: 5%;
    margin-top: 200px;
    margin-bottom: 50px;
    border-radius: 20px;
    position: relative;
}

.info-box {
    font-family: 'poppins';
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-row {
    font-family: 'poppins';
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.info-item {
    width: 120px;
    text-align: left;
    font-size: 18px;
    font-family: 'Poppins-bold';
    color: white;
}

.lower-description {
    font-family: 'Poppins';
    color: #98f94d;
    font-size: 20px;
    max-width: 38%;
    margin: 20px auto 50px 5%;
    text-align: center;
    line-height: 1;
}

.tokenomics-svg {
    position: absolute;
    right: -100%;
    top: 50%;
    transform: translateY(-50%);
    max-width: 640px;
    height: auto;
}

.utilities-text {
    font-family: 'PolenoSemiBold', sans-serif;
    color: white;
    font-size: 48px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 40px;
}

.utility-box {
    background-color: #157c48;
    font-family: 'Poppins-bold';
    color: white;
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    margin-bottom: 20px;
}

.utility-box.long {
    font-family: 'Poppins-bold';
    height: auto;
    padding: 30px;
    font-size: 18px;
    line-height: 1.5;
}

.utility-container {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 5%;
}

.utility-column-1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    left: 10%;
}

.utility-column-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.utility-column:first-child {
    margin-left: 25%;
}

.utility-column:last-child {
    margin-right: 10%;
}

.footer-section {
    background-color: #05A561;
    padding: 100px 0 600px 0;
    margin-top: 100px;
    position: relative;
}

.footer-background {
    background-color: white;
    height: 1670px;
    width: 100%;
    position: absolute;
    top: 100px;
    z-index: 0;
}

.footer-image {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.card-rocket-svg {
    width: 100%;
    height: 600px;
    margin-top: 18%;
    z-index: 1;
}

.roadmap-head {
    font-family: 'PolenoSemiBold', sans-serif;
    color: #36a964;
    font-size: 48px;
    position: absolute;
    left: 40%;
    top: 23%;
    transform: translateY(-50%);
    z-index: 2;
}

.roadmap-text {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: #36a964;
    font-size: 22px;
    position: absolute;
    left: 22%;
    right: 22%;
    top: 58%;
    transform: translateY(-50%);
    z-index: 2;
}

.memestiny-head {
    font-family: 'PolenoSemiBold', sans-serif;
    color: #36a964;
    font-size: 46px;
    position: absolute;
    right: 15%;
    top: 66%;
    transform: translateY(-50%);
    z-index: 2;
}

.memestiny-box-container {
    display: flex;
    justify-content: space-between;
    margin-top: 310px;
    padding: 0 15%;
    z-index: 2;
    position: relative;
}

.memestiny-img {
    width: 48%;
    height: auto;
    border-radius: 50px;
}

.memestiny-box-full {
    display: flex;
    justify-content: center;
    margin-top: 45px;
}

.memestiny-img-full {
    width: 70%;
    height: auto;
    border-radius: 50px;
}

.buy-guide {
    font-family: 'PolenoSemiBold', sans-serif;
    color: #ffffff;
    font-size: 48px;
    text-align: center;
    margin-top: 3%;
}

.buy-guide-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.buy-guide-row {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 900px;
    justify-content: space-between;
}

.buy-guide-box {
    background-color: #bde40f;
    color: #05A561;
    font-family: 'Poppins', sans-serif;
    padding: 30px;
    flex: 1;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    min-width: 200px;
    box-sizing: border-box;
}

.buy-guide-box h2 {
    font-size: 2.5vw;
    margin-bottom: 15px;
}

.buy-guide-box p {
    font-size: 2vw;
    margin: 0;
}

.social-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    margin-top: 40px;
    box-sizing: border-box;
}

.social-content {
    background-color: white;
    color: #05A561;
    font-family: 'PolenoSemiBold', sans-serif;
    font-size: 24px;
    width: 90%;
    height: 90%;
    max-width: 1200px;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    text-align: center;
    box-sizing: border-box;
    position: relative;
}

.social-icons-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.social-icon-box {
    text-align: center;
    width: 25%;
}

.social-icon-small {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
}

.social-icon {
    width: 50%;
    height: auto;
    max-width: 580px;
}

.social-icon-box p {
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-size: 18px;
    margin-top: 10px;
}

/* Modal CSS */
.modal {
    display: none;
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #333;
}

/* Genel Ayarlar */
.presale-body {
    background-color: #05A561;
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Header (Gumby Logo ve Wallet Butonu) */
.presale-header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.presale-logo-image {
    max-width: 100%;
    height: auto;
    width: 300px;
    transition: width 0.3s ease;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.presale-wallet-button {
    background-color: #083a0b;
    color: #29f35e;
    font-size: 18px;
    font-family: 'Poppins';
    padding: 12px 25px;
    border-radius: 15px 50px 30px 60px;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.2s ease;
    position: absolute;
    right: 20px;
}

.presale-wallet-button:hover {
    background-color: #066b0b;
}

/* Presale Banner */
.presale-banner {
    background-color: #083a0b;
    color: #fff;
    text-align: center;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.presale-banner p {
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: bold;
}

/* Rocket and Gumbyspace images container */
.presale-worldcloudy-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    margin-bottom: -20%;
    z-index: 2;
}

.presale-rocket-image {
    position: absolute;
    left: 10%;
    top: 18%;
    max-width: 25%;
    height: auto;
    z-index: 1;
}

.presale-gumbyspace-image {
    position: absolute;
    right: 10%;
    top: 20%;
    max-width: 30%;
    height: auto;
    z-index: 3;
}

/* Footer (World Cloudy Görseli) */
.presale-footer {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-top: -120px;
}

.presale-cloudy-image {
    width: 100vw;
    height: auto;
    min-width: 100%;
    max-width: 100%;
}

/* Mobil Cihazlar için Media Query */
@media (max-width: 950px) {
    .body {
        width: auto;
    }
    
    /* Header */
    .header {
        flex-direction: column;
        height: auto;
        padding: 10px;
    }

    .left-buttons {
        flex-direction: row;
        gap: 10px;
        position: static;
        margin-bottom: 15px;
    }

    .logo-image {
        max-width: 50%;
        height: auto;
        margin: 0 auto;
    }

    .right-button {
        margin-top: 15px;
        position: static;
        text-align: center;
    }

    /* Gumby Text */
    .gumby-text {
        font-size: 12vw;
        line-height: 1.1;
        padding-top: 10px;
    }

    /* Description Text */
    .description-text {
        font-size: 4vw;
        max-width: 90%;
        margin: 20px auto;
    }

    /* Additional Buttons */
    .additional-buttons {
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }

    /* World Cloudy Container */
    .world-cloudy-container {
        margin-top: 20%;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
    }

    img.rocket-image {
        max-width: 60%;
        left: -15%;
        top: 0;
        transform: translateY(-10%);
    }

    img.gumbyspace-image {
        max-width: 60%;
        left: 50%;
        top: 0;
        transform: translateY(-10%);
    }

    img.gumbyhero-image {
        max-width: 30%;
        left: 15%;
        transform: translateX(-50%);
        top: 44%;
    }

    /* About Gumby */
    .about-gumby {
        max-width: 70%;
        right: 3%;
        top: auto;
        margin-top: 40px;
        text-align: center;
    }

    .about-gumby h2 {
        font-size: 4vw;
        text-align: center;
    }

    .about-gumby p {
        font-size: 3vw;
        line-height: 1.4;
    }

    /* Lower Section */
    .lower-section {
        flex-direction: column;
        width: 80%;
        margin: 40px auto;
        padding: 20px;
    }

    .info-box {
        width: 90%;
        right: 3%;
    }

    .info-row {
        justify-content: space-between;
        width: 100%;
    }

    .info-item {
        font-size: 3vw;
        width: auto;
        text-align: center;
    }

    .lower-description {
        max-width: 80%;
        margin-top: 60%;
        right: 1%;
        text-align: center;
        line-height: 1.4;
    }

    /* Tokenomics SVG */
    .tokenomics-svg {
        margin-top: 25%;
        max-width: 90%;
        right: 50%;
        transform: translateX(50%);
    }

    /* Utilities */
    .utility-container {
        display: flex;
        flex-direction: column;
        gap: 20px; /* Kutucuklar arasında boşluk */
        margin: 0 auto;
        padding: 0 25%; /* Sağa sola boşluk ekler */
    }

    .utility-column-1 {
        width: 100%; /* Kutuların genişliğini tam genişlik yapar */
    }

    .utility-column-2 {
        width: 100%; /* Kutuların genişliğini tam genişlik yapar */
    }

    .utility-box {
        width: 90%; /* Kutu genişliğini tam genişlik yapar */
        text-align: center; /* Metni ortalar */
        right: 3%;
    }

    .utility-box.long {
        padding: 20px;
        font-size: 16px;
        right: 3%;
    }

    /* Footer Section */
    .footer-section {
        padding: 50px 0 300px 0;
    }

    .footer-background {
        width: 100%;
        height: 300vw; /* Boyutu otomatik olarak ayarlar */
        top: 0;
        position: relative; /* Mobilde tam genişlik için relative yapıldı */
        z-index: 1;
    }

    .footer-image {
        width: 100%;
        height: auto;
        position: relative; /* Mobilde tam genişlik için relative yapıldı */
        top: 0;
        left: 0;
        right: 0;
    }

    .roadmap-head {
        font-size: 6vw;
        left: 35%;
        top: 13%;
    }

    img.card-rocket-svg {
        max-width: 80%;
        transform: translateX(12%);
        top: -10%;
    }

    .roadmap-text {
        font-size: 4vw;
        left: 10%;
        right: 10%;
        top: 20%;
    }

    .memestiny-head {
        font-size: 5vw;
        right: 10%;
        top: 60%;
    }

    .memestiny-box-container {
        flex-direction: column;
        margin-top: 0%;
    }

    .memestiny-img {
        width: 90%;
        margin-bottom: 20px;
    }

    .memestiny-img-full {
        width: 90%;
    }

    /* Buy Guide */
    .buy-guide {
        font-size: 6vw;
        margin-top: 30px;
    }

    .buy-guide-container {
        padding: 20px;
    }

    .buy-guide-row {
        flex-direction: column;
    }

    .buy-guide-box {
        font-size: 4vw;
        padding: 20px;
    }

    /* Social Box */
    .social-box {
        height: auto;
    }

    .social-content {
        width: 90%;
        height: auto;
        right: 1.2%;
        padding: 20px; /* İçeriğin yukarı kaymasını sağlar */
        text-align: center;
    }

    .social-content h2 {
        font-size: 4vw; /* Yazı boyutunu küçültür */
        margin-bottom: 20px; /* Yazı ile simgeler arasındaki boşluk */
    }

    .social-icon {
        width: 45%; /* SVG görselin genişliğini küçültür */
        max-width: 400px;
        margin: 20px auto; /* Ortalar ve yukarı çeker */
    }

    .social-icons-container {
        flex-direction: column; /* Simgeleri alt alta dizer */
        gap: 20px; /* Simgeler arasındaki boşluk */
    }

    .social-icon-box {
        width: auto; /* Simgeleri daraltır */
        text-align: center; /* Simgeleri ve yazıları ortalar */
    }

    .social-icon-small {
        width: 50px; /* Küçük ikonların boyutunu ayarlar */
        height: auto;
    }

    .social-icon-box p {
        font-size: 3.5vw; /* Yazı boyutunu küçültür */
        margin-top: 5px; /* Yazı ve simge arasındaki boşluk */
    }
}

/* Media Query: Daha küçük cihazlar için */
@media (max-width: 768px) {
    .presale-logo-image {
        width: 200px;
    }
    .presale-wallet-button {
        font-size: 0.9rem;
        padding: 8px 16px;
        right: 20px; /* Mobil görünümde butonu biraz sola kaydırdık */
        top: 50%;
        transform: translateY(-50%);
    }
    .presale-rocket-image {
        left: 5%;
        top: 20%;
        max-width: 25%;
    }
    .presale-gumbyspace-image {
        right: 5%;
        top: 25%;
        max-width: 35%;
    }
}

@media (max-width: 480px) {
    .logo-image {
        width: 150px;
    }
    .wallet-button {
        font-size: 0.8rem;
        padding: 6px 12px;
        right: 15px; /* Küçük ekranlarda butonu daha sola kaydırdık */
        top: 50%;
        transform: translateY(-50%);
    }
    .rocket-image {
        left: 2%;
        top: 25%;
        max-width: 30%;
    }
    .gumbyspace-image {
        right: 2%;
        top: 30%;
        max-width: 40%;
    }
}
